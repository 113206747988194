<template>
  <section>
    <Search/>
    <EntryStock
        v-if="stock.product"
        v-for="(stock,index) in _stocks"
        :key="index"
        :stock="stock"
    />
  </section>
</template>

<script>
import Search from "@/views/v2/stock/entry/Search.vue";
import EntryStock from "@/views/v2/stock/entry/EntryStock.vue";
import {mapGetters} from "vuex";

export default {
  name: "EntryHome",
  components: {Search, EntryStock},
  computed: {
    ...mapGetters("stockEntry", ["_stocks"]),
  },
}
</script>

<style scoped>

</style>