<template>
  <b-card>
    <b-overlay
        :show="show"
        rounded="sm"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="2">
                <b-form-radio
                    v-model="activeStock.stock_return"
                    value="9"
                    class="custom-control-success"
                > Stok
                </b-form-radio>
                <b-form-radio
                    v-model="activeStock.stock_return"
                    value="27"
                    class="custom-control-danger"
                >
                  İade
                </b-form-radio>
                <b-form-radio
                    v-model="activeStock.stock_return"
                    value="50"
                    class="custom-control-primary"
                >
                  Temin
                </b-form-radio>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Tedarikçi">
                  <v-select
                      v-model="activeStock.supplier_id"
                      label="name"
                      :options="_suppliers"
                      :reduce="supplier => supplier.id"
                      class="select-size-xs"
                      placeholder="Tedarikçi Seçiniz"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Stok Rafı">
                  <v-select
                      v-model="activeStock.rack_id"
                      label="rack"
                      :options="_whereIsLeaf"
                      :reduce="item => item.id"
                      class="select-size-xs"
                      placeholder="Stok Rafı Seçiniz"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="KDV Dahil Fiyat">
                  <b-form-input
                      v-model="activeStock.price"
                      type="number"
                      size="xs"
                      placeholder="KDV Dahil Fiyat"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Stok Adedi">
                  <b-form-input
                      v-model="activeStock.quantity"
                      type="number"
                      size="xs"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Ürün Barcode">
                  <b-form-input
                      v-model="activeStock.search_barcode"
                      type="text"
                      size="xs"
                      placeholder="Ürün Barkodu Giriniz"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Yönetim">
              <b-button-group size="sm">
                <b-button
                    variant="primary"
                    @click="addNewActiveStock"
                > Ekle
                </b-button>
                <b-button :disabled="_stocks.length<1" @click="saveAllProduct" variant="success">Tümünü Kaydet
                </b-button>
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  name: "Search",
  components: {vSelect},
  data: () => ({
    show: false,
    activeStock: {
      id: null,
      supplier_id: null,
      stock_return: null,
      rack_id: null,
      quantity: 1,
      price: 0,
      search_barcode: '',
      timestamp: null,
      message: '',
    }
  }),
  computed: {
    ...mapGetters('supplier', ['_suppliers']),
    ...mapGetters('stockRack', ['_whereIsLeaf']),
    ...mapGetters("stockEntry", ["_stocks"]),
  },
  methods: {
    addNewActiveStock() {
      this.show = true
      this.activeStock.timestamp = Date.now();
      if (!(this.activeStock.supplier_id && this.activeStock.stock_return && this.activeStock.rack_id && this.activeStock.search_barcode && this.activeStock.quantity)) {
        this.show = false
        this.$swal({
          title: 'Başarısız',
          text: 'Bilgiler eksik girilemez',
          icon: 'warning',
          timer: 2000,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        return
      }

      this.$store.dispatch('stockEntry/addNewStock', this.activeStock)
          .then(res => {
            if (res.product) {
              this.$swal({
                title: 'Başarılı',
                text: 'Ürün Bulundu',
                icon: 'success',
                timer: 1000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$swal({
                title: 'Dikkat',
                text: 'Ürün Bulunumadı',
                icon: 'error',
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }

            this.show = false
            this.activeStock.search_barcode = null
            this.activeStock.quantity = 1
            this.activeStock.price = 0
          })
    },
    saveAllProduct() {
      this._stocks.forEach(stock => {
        if (stock.id == null) {
          this.$store.dispatch('activeStock/store', stock)
              .then(res => {
                stock.id = res.content.id;
                stock.message = res.message;
                //let index = this._stocks.findIndex(item => item.timestamp === stock.timestamp)
                //if (index > -1) this._stocks[index].message = res.message;
              })
        }
      })
    }
  },
  mounted() {
    this.$store.dispatch('supplier/suppliers');
    this.$store.dispatch('stockRack/getWhereIsLeaf');
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>