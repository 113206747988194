<template>
  <b-card>
    <b-row>
      <b-col cols="2">
        <div class="imageContainer m-0 p-0" v-if="stock.product && stock.product.images">
          <div class="clearfix border" v-if="stock.product.images[0]">
            <b-img class="border border-primary" :src="'https://api.kitapfirsatlari.com/' + stock.product.images[0].url"
                   width="150"/>
          </div>
        </div>
      </b-col>
      <b-col cols="2">
        <b-badge variant="success">{{ stock.supplier_id }}</b-badge>
        <br>
        <b-badge v-if="stock.stock_return==9">Stok</b-badge>
        <b-badge v-if="stock.stock_return==27">İade</b-badge>
        <b-badge v-if="stock.stock_return==50">Temin</b-badge>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <b-badge>{{ stock.product.barcode }}</b-badge>
            <b-badge variant="dark" v-if="stock.product && stock.search_barcode!==stock.product.barcode">
              {{ stock.product.barcode }}
            </b-badge>

            <div class="mt-1" v-if="stock.product && stock.product.order_products">
              <div v-for="(orderProduct,opIndex) in stock.product.order_products" :key="opIndex">
                <b-badge variant="danger"> Sipariş: {{ orderProduct.count }}</b-badge>
                <b-badge variant="danger"> Adet: {{ orderProduct.sum }}</b-badge>
              </div>

            </div>
          </b-col>
          <b-col>
            <b-badge v-if="stock.product">{{ stock.product.id }}</b-badge>
            <br>
            <b-badge v-if="stock.product">{{ stock.product.name }}</b-badge>
          </b-col>
          <b-col>
            <b-form-input type="text" size="xs" v-model="stock.quantity"/>
          </b-col>
          <b-col>
            <b-form-input type="text" size="xs" v-model="stock.price"/>
          </b-col>

        </b-row>
        <b-row class="mt-2">
          <b-overlay :show="show">{{ stock.message }}</b-overlay>
        </b-row>

      </b-col>

      <b-col cols="1">
        <b-badge variant="success" v-if="stock.id">Kayıt No: {{ stock.id }}</b-badge>
        <br>
        <b-button size="sm" :disabled="saveBtn || stock.id" @click="saveProduct" variant="success">Kaydet</b-button>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import {BBadge} from "bootstrap-vue";

export default {
  name: "EntryStock",
  components: {BBadge},
  data: () => ({
    show: false,
    saveBtn: false,
  }),
  props: {
    stock: {
      type: Object,
      required: true
    }
  },
  methods: {
    saveProduct() {
      this.saveBtn = true;
      this.stock.message = '';
      this.show = true;
      this.$store.dispatch('activeStock/store', this.stock)
          .then(res => {
            this.stock.message = res.message;
            this.show = false
          })
    }
  }
}
</script>

<style scoped>

</style>